import { $window, $document, $body, activeClass } from '../utils/globals';

$('.js-filter').each(function() {
	const $filter = $(this);
	const $filterTrigger = $filter.find('.js-filter-trigger');
	let wasLastElementFocused;

	$filterTrigger.on('click', function(evt) {
		evt.preventDefault();

		if (!$filter.hasClass(activeClass)) {
			$filter.addClass(activeClass);
			$filterTrigger.attr('aria-expanded', 'true');
		} else {
			$filter.removeClass(activeClass);
			$filterTrigger.attr('aria-expanded', 'false');
		}
	})

	$filterTrigger.focusin(function() {
		$filter.addClass('is-focused');
	})

	$document.keyup(function(e) {
		let code = e.keyCode || e.which;

		if (code == '9') {
			if (wasLastElementFocused == true) {
				$filter.removeClass('is-active is-focused');
				$filterTrigger.attr('aria-expanded', 'false');

				wasLastElementFocused = false;
			}

			wasLastElementFocused = $filter.find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').last().is(':focus');
		}
	});
})

$document.on('click touchstart', function (evt) {
	if ($(evt.target).closest($('.js-filter')).length === 0) {
		//Do something if the click its outside of the element

		$('.js-filter').removeClass(activeClass);
	}
});
