import { $window, $document, $body } from '../utils/globals';
import 'slick-carousel';

$('.js-slider').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-images').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');

	$sliderSlides.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		infinite: true,
		arrows: false,
		autoplay: 5000,
		fade: true,
		cssEase: 'linear',
		speed: 800,
	})
});

$('.js-slider-events').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');
	const options = {
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	}

	$sliderSlides.slick(options);

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}

	$window.on('load resize orientationChange', function() {
		if ($this.hasClass('js-slider-events-unslick-mobile')) {
			if ($sliderSlides.hasClass('slick-initialized')) {
				$sliderSlides.slick('slickSetOption', {
					responsive: [
						{
							breakpoint: 768,
							settings: "unslick",
						}
					],
				}, true);
			} else if ($window.width() > 767) {
				$sliderSlides.slick(options);
			}
		}
	})
});

$('.js-slider-news-gallery').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');
	const $dotsContainer = $this.find('.slider__dots');

	$sliderSlides.slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	})

	if ($this.hasClass('js-slider-news-gallery--dots')) {
		$sliderSlides.slick('slickSetOption', {
			dots: true,
			appendDots: $dotsContainer,
		}, true);
	}

	if ($this.hasClass('js-slider-news-gallery-fluid')) {
		$window.on('load resize orientationChange', function() {
			setTimeout(function() {
				if ($window.width() > 767) {
					$sliderSlides.slick('slickSetOption', {
						variableWidth: true,
						slidesToShow: 1,
						responsive: [
							{
								breakpoint: 992,
								settings: {
									slidesToShow: 1,
									variableWidth: true,
								}
							},
							{
								breakpoint: 768,
								settings: {
									slidesToShow: 1,
									variableWidth: false,
								}
							}
						],
					}, true);

					$sliderSlides.slick('setPosition');
				}
			}, 50);
		})
	}

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-video-gallery').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	})


	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-gallery-images').each(function() {
	const $this = $(this);
	const $sliderGalleryImagesParent = $this.closest('.js-slider-gallery-images-parent');
	const $sliderSlides = $this.find('.slider__slides');
	const $dotsContainer = $sliderGalleryImagesParent.find('.slider__dots');
	const $prevArrow = $sliderGalleryImagesParent.find('.slider__arrow--prev');
	const $nextArrow = $sliderGalleryImagesParent.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		fade: true,
		cssEase: 'linear',
		speed: 800,
		infinite: false,
		appendDots: $dotsContainer,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					autoplay: true,
					autoplaySpeed: 4000,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-members').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1.25,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-photo-gallery-fluid').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		variableWidth: true,
	});

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}

	$sliderSlides.on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if ($sliderSlides.find('.slick-slide:last-child').data('slick-index') == nextSlide) {
			$this.addClass('last-slide-reached');
		} else {
			$this.removeClass('last-slide-reached');
		}
	});

	$window.on('load resize', function() {
		$sliderSlides.find('figcaption').each(function() {
			const $figCaption = $(this);
			const figCaptionHeight = $figCaption.height();
			const $parent = $figCaption.parent();

			if ($window.width() < 768) {
				$parent.css('padding-bottom', (figCaptionHeight + 10) + 'px');
				$sliderSlides.css('--caption-height', (figCaptionHeight + 10) + 'px');
			} else {
				$parent.removeAttr('style');
				$sliderSlides.removeAttr('style');
			}
		})
	})
});

$('.js-slider-services').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					adaptiveHeight: true,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-publications').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					adaptiveHeight: true,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-posts-instagram').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});

$('.js-slider-awards').each(function() {
	const $this = $(this);
	const $sliderSlides = $this.find('.slider__slides');
	const $prevArrow = $this.find('.slider__arrow--prev');
	const $nextArrow = $this.find('.slider__arrow--next');

	$sliderSlides.slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		prevArrow: $prevArrow,
		nextArrow: $nextArrow,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				}
			}
		],
	})

	const slidesCount = $sliderSlides.find('.slider__slide').length;
	let slidesToShowCount = $sliderSlides.slick('getSlick').options.slidesToShow;

	if (slidesCount <= slidesToShowCount) {
		$this.addClass('hide-controls')
	}
});
