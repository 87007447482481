$('.js-read-more').each(function() {
	const $this = $(this);
	const $input = $this.find('input');
	const $label = $this.find('label');

	$input.on('change', function() {
		if ($(this).is(':checked')) {
			$(this).attr('aria-label', 'read less');
		} else {
			$(this).attr('aria-label', 'read more');
		}
	})
})
