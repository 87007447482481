import { $window, $document, $body } from '../utils/globals';

$('.js-nav-sub-page').each(function() {
	const $nav = $(this);
	const $dropdown = $nav.find('li.has-dropdown .nav__dropdown');

	$nav.find('li.has-dropdown').append('<i class="nav__dropdown-icon"></i>');

	$nav.find('> ul > li > a').focusin(function() {
		removePadding();
		$nav.find('li').removeClass('is-active');

		$(this).parent().addClass('is-active');
	})

	function addPadding(dropdownHeight) {
		$nav.stop().css('padding-bottom', dropdownHeight + 'px');
	}

	function removePadding() {
		$nav.stop().css('padding-bottom', 0 + 'px');
	}

	$dropdown.each(function() {
		const $this = $(this);
		const $parent = $this.parent();
		let dropdownHeight = $this.outerHeight();

		$window.on('load resize', function() {
			dropdownHeight = $this.outerHeight();
		})

		$parent.on('mouseenter', function() {
			addPadding(dropdownHeight);
		})

		$parent.on('mouseleave', function() {
			removePadding();
		})

		$parent.find('> a').focusin(function() {
			removePadding();
			$nav.find('li').removeClass('is-active');

			$parent.addClass('is-active');
			addPadding(dropdownHeight);
		})
	})
})
