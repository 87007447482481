import { $window, $document, $body } from '../utils/globals';

// Hide Header on on scroll down
const $header = $('.header');
const isScrolling = 'is-scrolling';
const scrollingUp = 'is-scrolling-up';
const scrollingDown = 'is-scrolling-down';
let lastScrollTop = 0;
let delta = 5;
let headerHeight = $header.outerHeight();
let scrollTopValue = $window.scrollTop();

$window.on('scroll', function(event){
	scrollTopValue = $window.scrollTop();

	hasScrolled();

	if (scrollTopValue <= headerHeight) {
		$header.removeClass('is-scrolling is-scrolling-down is-scrolling-up');
	} else {
		$header.addClass(isScrolling);
	}
});

function hasScrolled() {
	// Make sure they scroll more than delta
	if(Math.abs(lastScrollTop - scrollTopValue) <= delta)
		return;

	// If they scrolled down and are past the header, add class .is-scrolling-down .
	// This is necessary so you never see what is "behind" the navbar.
	if (scrollTopValue > lastScrollTop && scrollTopValue > headerHeight){
		// Scroll Down
		$header.removeClass(scrollingUp).addClass(scrollingDown);
	} else {
		// Scroll Up
		if(scrollTopValue + $window.height() < $document.height() && scrollTopValue > headerHeight) {
			$header.removeClass(scrollingDown).addClass(scrollingUp);
		}
	}

	lastScrollTop = scrollTopValue;
}
