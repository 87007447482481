import { $window, $document, $body, activeClass } from '../utils/globals';

const $caption = $('.js-caption');

$document.ready(function() {
	$caption.each(function() {
		const $currentCaption = $(this);
		const $captionTrigger = $currentCaption.find('.caption__trigger');

		$captionTrigger.on('click', function(evt) {
			evt.preventDefault();

			if ($body.hasClass('is-touch-device')) {
				$currentCaption.toggleClass(activeClass);
			}
		})

	})
})

//Open caption popup on keyboard tab press
$document.keyup(function(evt) {
	let code = evt.keyCode || evt.which;

	if (code == '9') {
		if ($(evt.target).closest('.js-caption').length == 1) {
			$(evt.target).closest('.js-caption').addClass(activeClass)
		} else {
			$('.js-caption').removeClass(activeClass);
		}
	}
});

$document.on('click touchstart', function (evt) {
	if ($(evt.target).closest($caption).length === 0) {
		//Do something if the click its outside of the element

		$caption.removeClass(activeClass);
	}
});
