import 'selectric';

$('.js-select').selectric({
	disableOnMobile: false,
	nativeOnMobile: false
});

$('.js-select').on('change',function(){
	var target = '#' + $(this).val();
	$(target).addClass('current').siblings().removeClass('current')
})