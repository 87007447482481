function initVideos() {
	const videos = Array.from(document.querySelectorAll('.js-video'));
	const ratio = 16/9;

	//Scaling the iframe to fit always the container
	function iframeScale(iframe) {
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;
		const scale =  ((windowWidth / windowHeight) > ratio) ? (windowWidth / (ratio * windowHeight)) : (windowHeight * ratio / windowWidth);

		iframe.style.transform = 'scale(' + scale + ')';
	}

	videos.map(function (video) {
		const videoPlayer = video.querySelector('.video__player');
		const videoId = videoPlayer.dataset.plyrEmbedId;
		const videoType = videoPlayer.dataset.plyrProvider;
		const toggleVolumeBtn = video.querySelector('.video__btn--toggle-volume');
		const togglePlayBtn = video.querySelector('.video__btn--toggle-play');
		let toggleVolumeBtnState = toggleVolumeBtn.dataset.state;
		let togglePlayBtnState = togglePlayBtn.dataset.state;

		const player = new Plyr(videoPlayer, {
			clickToPlay: false,
			loop: {
				type: 'video',
				active: true,
			},
			sources: [{
				src: videoId,
				type: videoType,
			}],
			muted: true,
			playsinline: true,
			controls: false,
		});

		player.on('ready', () => {
			player.volume = 0;
			player.play();

			videoIframe = video.querySelector('.plyr__video-embed__container iframe');
			iframeScale(videoIframe)
		})

		window.addEventListener('resize', function() {
			iframeScale(videoIframe)
		})

		togglePlayBtn.addEventListener('click', (event) => {
			event.preventDefault();
			if (togglePlayBtnState == 'playing') {
				player.pause();

				togglePlayBtn.dataset.state = 'paused';
			} else {
				player.play();
				togglePlayBtn.dataset.state = 'playing';
			}

			togglePlayBtnState = togglePlayBtn.dataset.state;
		});

		toggleVolumeBtn.addEventListener('click', (event) => {
			event.preventDefault();
			if (toggleVolumeBtnState == 'muted') {
				player.volume = 1;

				toggleVolumeBtn.dataset.state = 'audio';
			} else {
				player.volume = 0;
				toggleVolumeBtn.dataset.state = 'muted';
			}

			toggleVolumeBtnState = toggleVolumeBtn.dataset.state;
		});
	})
}

initVideos();
