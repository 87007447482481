import { $window, $document, $body } from '../utils/globals';

$('.js-elements-positions').each(function() {
	const $elementsPositions = $(this);
	let containerWidth = $elementsPositions.width();

	$elementsPositions.find('.js-element-position').each(function() {
		const $elementPosition = $(this);
		let elementLeftPosition = $elementPosition.position().left;

		$window.on('load resize', function() {
			containerWidth = $elementsPositions.width();
			elementLeftPosition = $elementPosition.position().left;

			if (elementLeftPosition + ($elementPosition.width() / 2) > containerWidth / 2) {
				$elementPosition.addClass('is-right-side');
			} else {
				$elementPosition.removeClass('is-right-side');
			}
		})
	})
})
