function initDatepicker() {
	$('.datepicker').each(function(index, el) {
		var item = $(el)
		var year = new Date().getFullYear();
		var monthsArr = ["January " + year, "February " + year, "March " + year, "April " + year, "May " + year, "June " + year, "July " + year, "August " + year, "September " + year, "October " + year, "November " + year, "December " + year]
		$(item).pickadate({
			selectYears: false,
			selectMonths: true,
			weekdaysShort: ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'],
			monthsFull: monthsArr,
			onOpen: function() {
			    var datepickerWrapper = $(item).parents('.datepicker-wrapper');
			    $(datepickerWrapper).addClass('open')
			},
			onClose: function() {
			    var datepickerWrapper = $(item).parents('.datepicker-wrapper');
			    $(datepickerWrapper).removeClass('open')
			},
		})
	});
}

$(".section-calendar").on('keyup', '.picker__button--today', function(e) { 
  var keyCode = e.keyCode || e.which; 
  if (keyCode == 9) { 
    e.preventDefault(); 
    $('.datepicker-wrapper').removeClass('open')
  } 
});


$(window).on('load',function(){
	initDatepicker()
})

