import { $window, $document, $body, activeClass } from '../utils/globals';

$document.keyup(function(evt) {
	let code = evt.keyCode || evt.which;

	if (code == '9') {
		if ($(evt.target).closest('.nav__dropdown-list-links')) {
			let dropdownTarget = $(evt.target).parent().data('dropdown-target');

			if (dropdownTarget !== undefined) {
				$('.nav .nav__dropdown-submenu[data-dropdown="'+ dropdownTarget + '"] .nav__dropdown-submenu-lists-links').find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').eq(0).trigger('focus');
			}
		}

		let dropdownTarget = $('.nav .nav__dropdown-list-links li.is-active').data('dropdown-target');
		let $lastFocusableElement = $('.nav .nav__dropdown-submenu[data-dropdown="'+ dropdownTarget + '"] .nav__dropdown-submenu-lists-links').find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').last();

		if ($('.nav .nav__dropdown-submenu[data-dropdown="'+ dropdownTarget + '"]').hasClass(activeClass)) {
			if ($lastFocusableElement.is(':focus')) {
				$('.nav li[data-dropdown-target="'+ dropdownTarget + '"]').next().find('a').focus();
			}
		}
	}
});

$document.ready(function() {
	const $nav = $('.nav');

	$('.nav li.has-dd > a').on('click', function() {
		if ($window.width() < 992) {
			event.preventDefault();

			$(this).parent().toggleClass(activeClass);
			$(this).next().slideToggle();
		} else {
			if ($body.hasClass('is-touch-device')) {
				event.preventDefault();

				$(this).parent().addClass(activeClass);
			}
		}
	})

	$nav.find('.nav__dropdown-list-links a').each(function() {
		const $this = $(this);
		const $parent = $this.parent();
		const dropdownTarget = $parent.data('dropdown-target');

		const openMenu = () => {
			$nav.find('.nav__dropdown-submenu').removeClass(activeClass);
			$parent.addClass(activeClass).siblings().removeClass(activeClass);
			$('.nav .nav__dropdown-submenu[data-dropdown="'+ dropdownTarget + '"]').addClass(activeClass);
		}

		$this.focusin(function() {
			openMenu();
			// $('.nav .nav__dropdown-submenu[data-dropdown="'+ dropdownTarget + '"]').attr('tabindex', 0);
		})

		if ($body.hasClass('is-touch-device')) {
			$this.on('click', function(evt) {
				evt.preventDefault()
				openMenu();
			})
		} else {
			if ($window.width() > 991) {
				$this.on('mouseenter', function() {
					openMenu();
				})
			} else if ($window.width() < 992) {
				$this.on('click', function(evt) {
					if ($this.hasClass('js-dropdown-trigger')) {
						evt.preventDefault();
						let dropdownHeight = $('.nav .nav__dropdown-submenu[data-dropdown="'+ dropdownTarget + '"]').outerHeight();

						$this.closest('.nav__dropdown-inner').css('height', dropdownHeight);

						openMenu();
					}
				})
			}
		}
	});

	$nav.find('.nav__dropdown-list-links').parent().on('mouseleave', function() {
		if ($window.width() > 991) {
			$nav.find('.nav__dropdown-submenu').removeClass(activeClass);
			$nav.find('.nav__dropdown-list-links li').removeClass(activeClass);
		}
	})

	$('.js-dropdown-back').on('click', function(evt) {
		evt.preventDefault();
		const $backBtn = $(this);
		const $parent = $backBtn.parent();
		const dropdownName = $parent.data('dropdown');
		const $trigger = $('.js-dropdown-trigger[data-dropdown-target="' + dropdownName + '"]');

		$nav.find('.nav__dropdown-inner').css('height', 'auto');
		$parent.removeClass(activeClass);
		$trigger.parent().removeClass(activeClass);
	})
})

$document.on('click touchstart', function (evt) {
	if ($body.hasClass('is-touch-device')) {
		if ($(evt.target).closest('.nav').length === 0) {
			$('.nav li.has-dd').removeClass(activeClass);
		}
	}
});
