import { $window, $document, $body, activeClass } from '../utils/globals';

$('.js-table-trigger').on('click', function(event) {
	event.preventDefault();
	const $tableTrigger = $(this);
	$tableTrigger.toggleClass(activeClass)
		.closest('tr')
			.find('.table__inner')
			.slideToggle();
})

$window.on('load resize', function() {
	if ($window.width() > 767) {
		$('.js-table-trigger').removeClass(activeClass);

		$('.table-schedule .table__inner').removeAttr('style');
	}
})
