import { $window, $document, $body } from './utils/globals';

/*
 * Modules
 */

const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

if (isTouchDevice()) {
	$body.addClass('is-touch-device');
}

import './modules/handle-viewport-heights.js';
import './modules/handle-sliders.js';
import './modules/handle-bootstrap.js';
import './modules/handle-header.js';
import './modules/handle-captions.js';
import './modules/handle-aos.js';
import './modules/handle-scroll-to.js';
import './modules/handle-dropdowns.js';
import './modules/handle-nav-trigger.js';
import './modules/handle-plyr.js';
import './modules/handle-filter.js';
import './modules/handle-elements-positions.js';
import './modules/handle-member-popup.js';
import './modules/handle-nav-sub-page.js';
import './modules/handle-table-accordions.js';
import './modules/handle-read-more.js';
import './modules/handle-select.js';
import './modules/handle-popup.js';
import './modules/handle-datepicker.js';



