import { $window, $document, $body } from '../utils/globals';

const $header = $('.header');
const $navTrigger = $('.js-nav-trigger');
const isActive = 'is-active';

$navTrigger.on('click', function(evt) {
	evt.preventDefault();

	$(this).toggleClass(isActive);
	$header.toggleClass(isActive);
	$body.toggleClass('menu-opened');

	$('.nav li.has-dd').removeClass(isActive).find('.nav__dropdown').slideUp();
	$('.nav .nav__dropdown-list-links li').removeClass(isActive);
	$('.nav .nav__dropdown-inner').removeAttr('style');
	$('.nav .nav__dropdown-submenu').removeClass(isActive);
})

$window.on('load resize', function() {
	if ($window.width() > 1023) {
		$navTrigger.removeClass(isActive);
		$header.removeClass(isActive);
		$body.removeClass('menu-opened');
		$('.nav li.has-dd').removeClass(isActive);
		$('.nav .nav__dropdown').removeAttr('style');
		$('.nav .nav__dropdown-list-links li').removeClass(isActive);
		$('.nav .nav__dropdown-submenu').removeClass(isActive);
	}
})
