import { $window, $document, $body, activeClass } from '../utils/globals';

$('.js-member-popup-trigger').each(function() {
	const $memberPopupTrigger = $(this);
	const $member = $memberPopupTrigger.closest('.member');
	const $memberPopup = $member.find('.member__popup');

	$memberPopupTrigger.on('click', function(evt) {
		evt.preventDefault();

		$('.member').removeClass(activeClass);
		$member.addClass(activeClass);
	})

	$memberPopup.focusout(function() {
		$member.removeClass(activeClass);
	})
})

$document.on('click touchstart', function (evt) {
	if ($(evt.target).closest($('.member')).length === 0) {
		//Do something if the click its outside of the element
		$('.member').removeClass(activeClass).find('.member__text input').prop('checked', false);
	}
});
